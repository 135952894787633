import { Component} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      state('close', style({
        width: '35%',
      })),
      state('open', style({
        width: '100%',
        borderRight: '0px solid #116DA3',
        borderRadius: '45px',
      })),
      transition('open <=> close', [
        animate('500ms ease-in')
      ])
    ]),
    // trigger('show', [
    //   transition(':enter', [
    //     style({ opacity: 0 }),
    //     animate('700ms 300ms ease-in', style({ opacity: 1 }))
    //   ]),
    // ]),
    // trigger('showLogo', [
    //   transition(':enter', [
    //     style({ opacity: 0 }),
    //     animate('1000ms 1500ms ease-in', style({ opacity: 1 }))
    //   ]),
    // ]),
    trigger('showChatbot', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms 100ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms 100ms ease-in', style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class AppComponent {
  title = 'front-end';

  isExpanded = false; // is expanded left content shown
  ExpandedScreenNum = 0; // screen number
  ExpandedAnimationOver = false; // left expansion over

  // isOpen = false;
  // showChatbot = true; 
  which_test = -1; // 0 ~ MI | 1 ~ CI | -1 ~ not set
  // Message Array
  messages = [];
  sessionId = Math.random().toString(36).slice(-5); // Current Session

  intelligences: string[];
  scores: number[];

  // intelligences = ["Artistic", "Enterprising", "Investigative", "Social", "Conventional", "Realistic"];
  // scores = [79, 75, 74, 73, 68, 66];
  // intelligences = ['Musical-Rhythmic', 'Visual-Spatial', 'Naturalist', 'Verbal-Linguistic', 'Interpersonal', 'Bodily-Kinesthetic', 'Logical-Mathematical', 'Intrapersonal'];
  // scores = [89.33, 88.00, 75.86, 70.00, 67.33, 64.00, 60.67, 57.42];


  toggle_animate() {
    // this.isOpen = !this.isOpen;
    // this.isShown = !this.isShown;
    // this.showChatbot = !this.showChatbot;

    // console.log(`isOpen: ${this.isOpen} isShown: ${this.isShown} showChatbot: ${this.showChatbot}`);
    this.extract_test_data();
    this.isExpanded = !this.isExpanded;
  }

  // debug method
  next_screen() {
    if (this.ExpandedScreenNum > 6) {
      this.toggle_animate();
      this.ExpandedAnimationOver = true;
    }
    this.ExpandedScreenNum += 1;
  }

  finishAnimation() {
    this.toggle_animate();
    this.ExpandedAnimationOver = true;
  }

  // debug method
  previous_screen() {
    this.ExpandedScreenNum -= 1;
  }

  // show_messages() {
  //   console.log(`isExpanded: ${this.isExpanded} over: ${this.ExpandedAnimationOver}`);
  // }

  receiveMessage($event) {
    // this.check_test();
    this.toggle_animate();
    this.next_screen();
    // console.log('animation_start');
  }

  // check_test() {
  //   this.messages.forEach(element => {
  //     if (element.sender == 'You') {
  //       let upper = element.text.toUpperCase();
  //       if (upper.includes('MI')) {
  //         this.which_test = 0;
  //       }
  //       else if (upper.includes('CI')) {
  //         this.which_test = 1;
  //       }
  //     }
  //   });
  // }

  check_test($event) {
    this.which_test = $event;
  }

  extract_test_data() {
    let test_data = {};
    this.messages.forEach(message => {
      // for (let tag in message) {
      //   if (message.payload) {
      //     test_data = message.payload.fields;
      //   }
      // }
      if (message.sender == "Mathew") {
        if (message.intent == "GetDetails" && 'payload' in message) {
          test_data = message.payload.fields;
        }
      }
    });

    let items = Object.keys(test_data).map(function (key) {
      return [key, test_data[key]['numberValue']]
    })

    items.sort((first, second) => {
      return second[1] - first[1];
    })
    // console.log(items);

    this.intelligences = items.map(item => {
      return item[0]
    });

    this.scores = items.map(item => {
      return item[1]
    });
  }

}
