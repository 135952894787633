import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 }))
      ]),
    ]),
    trigger('showLogo', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms 1500ms ease-in', style({ opacity: 1 }))
      ]),
    ]),
    trigger('showChatbot', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms 100ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms 100ms ease-in', style({ opacity: 0 }))
      ]),
    ])
  ]
})
export class LeftSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() ExpandedAnimationOver;
  @Input() isExpanded;
  @Input() ExpandedScreenNum;

  @Input() which_test;

  // @Input() messages; //temp
  // @Input() messages;
  // @Input() isOpen;
  // @Input() showChatbot;

  // intelligences = ['Musical-Rythmic', 'Visual-Spatial', 'Naturalist', 'Verbal-Linguistic', 'Interpersonal', 'Bodily-Kinesthetic', 'Logical-Mathemical', 'Intrapersonal'];
  // scores = [89.33, 88.00, 75.86, 70.00, 67.33, 64.00, 60.67, 57.42];

  @Input() intelligences;
  @Input() scores;

  intelligence_descriptions = {
    "Bodily-Kinesthetic": ["Bodily - Kinesthetic intelligence is the ability to use fine and gross motor skills in sports, the performing arts, or arts and crafts production. It the capacity to manipulate objects and use a variety of physical skills to solve problems, create products, and convey ideas and emotions.", "It involves control of \"voluntary\" movements; control of \"preprogrammed\" movements; expanding awareness through the body; the mind and body connection; mimetic abilities; improved body functioning.", "People with this intelligence are skilled at using their body to convey feelings and ideas. They have good hand-eye coordination and are very aware of their bodies. Their fine and gross motor skills are more advanced than the average person's. They enjoy building things and figuring out how things work. They like to use their hands and are very active."],
    "Interpersonal": ["Interpersonal Intelligence is the ability to understand other people, to notice their goals, motivations, intentions, and to work effectively with them. It involves effective verbal/non- verbal communication; sensitivity to other's moods, temperaments, motivations, and feelings; working cooperatively in a group; ability to discern other's underlying intentions and behavior; \"passing over\" into the perspective of another; and creating and maintaining synergy.", "People with Interpersonal intelligence thrive with social interaction and fall in the group of extroverts. They are sensitive to the moods and sentiments of others. They are gifted at establishing rapport with strangers and make friends easily. They work well with others and possess the ability to cooperate with people around them. They are masters at collaboration and are good team players.", "Others often seek them out for advice, help and comfort. They are good at making others feel welcome and often reach out to outsiders."],
    "Intrapersonal": ["Intrapersonal intelligence is the ability to gain access to understand one's inner feelings, dreams and ideas. It refers to having an understanding of yourself, of knowing who you are, what you can do, what you want to do, how you react to things, which things to avoid, and which things to gravitate toward. This form of intellect entails the ability to understand one's own emotions, goals and intentions.", "It involves concentration of the mind; mindfulness; awareness and expression of different feelings; transpersonal sense of the self, and higher-order thinking and reasoning. People who have high intrapersonal intelligence are aware of their emotions, motivations, beliefs, and goals. They are in touch with themselves, who they are, what they need and what they can accomplish. They are intuitive and usually introverted and learn independently and through reflection. They are also good at helping others understand themselves."],
    "Logical-Mathematical": ["Logical - Mathematical intelligence is the ability to explore patterns, categories and relationships by manipulating objects or symbols, and to experiment in a controlled, orderly way. It involves abstract pattern recognition; inductive reasoning; deductive reasoning; discerning relationships & connections; performing complex calculations, and scientific reasoning.", "People with Logical - Mathematical intelligence process information through logic and reason. They are attracted to investigation by means of the scientific method. They do well with quantitative methods that rely on the researcher's ability to observe and reach a verifiable conclusion. They dislike nebulous assumptions and subjective analysis. Instead, they trust hard facts and numerical data. They seek accuracy and precision in their learning and work. They also have a liking for all things organized. They like problem solving and believe in getting to the root of the problem, analyze patterns, detect the reason behind event occurrence, and arrive at a solution. They will not believe in anything without strong evidence."],
    "Musical-Rhythmic": ["Musical - Rhythmic intelligence is the ability to recognise and use rhythmic and tonal patterns, and sensitivity to sounds from the environment, the human voice, and musical instruments. It includes sensitivity to pitch, timbre rhythm of sounds, as well as responsiveness to the emotional implications of these elements.", "It also involves appreciation for the structure of music; schemes or frames in the mind for hearing music; sensitivity to sounds; recognition, creation, and reproduction of melody/rhythm; and sensing characteristic qualities of tone. It is the capacity to think music, to be able to hear patterns, recognize them, and manipulate them. People who have strong musical intelligence don't just remember music easily - they can't get it out of their minds.", " They have a \"good ear\" for music and can easily learn songs and melodies. They notice when someone is singing off-key. They are also good at imitating sounds or other people's voice or intonation. Rhythm and music can be a way for them to memorize concepts. Some people with musical intelligence are especially gifted at composing, singing or playing an instrument. Of all forms of intelligence, the consciousness altering effect of music and rhythm on the brain is probably the greatest."],
    "Naturalist": ["Naturalist intelligence refers to the human ability to discriminate among living things (plants, animals) as well as sensitivity to other features of the natural world (clouds, rock configurations). It focuses on how people relate to their natural surroundings.", "People with Naturalist intelligence have a sensitivity to and appreciation for nature. They have a special ability to grow plants, vegetables and fruit. They also have an affinity for animals and are good at training and understanding them. They love to walk, climb, camp and hike. They enjoy the outdoors and see the subtle meanings and patterns in nature and the world around them. People with naturalist intelligence are inspired and rejuvenated by nature."],
    "Verbal-Linguistic": ["Verbal - linguistic intelligence is the ability to use spoken and written language to excite, please, convince, stimulate or convey information. This involves not only ease in producing language, but also sensitivity to the nuances, order and rhythm of words. It also involves understanding order and meaning of words; convincing someone of a course of action; explaining, teaching, and learning; humor; memory & recall, and grammatical analysis.", "It also covers using your native language, and perhaps other languages, to express what's on your mind and to understand other people. People with verbal - linguistic intelligence love words. They enjoy reading and writing and are often gifted at learning languages. They appreciate the complexities of sentence structure, word structure, meaning and sound. They savor convoluted words and are quick at learning them. They also possess rich vocabularies. They enjoy lectures and taking notes. They also have the ability to teach others and communicate complex concepts."],
    "Visual-Spatial": ["Visual - Spatial intelligence is the ability to perceive and mentally manipulate a form or object, and to perceive and create tension, balance and composition in a visual or spatial display. It is the ability to create visual-spatial representations of the world and to transfer those representations either mentally or concretely. It involves active imagination; forming mental images; finding your way in space; image manipulations; graphic representation; recognizing relationships of objects in space, and accurate perception from different angles.", "People with visual - spatial intelligence are able to represent the spatial world internally in their mind. They easily imagine things vividly in their minds and are usually very creative. They have a sharp sense of space, distance and measurement. They are also very aware of their surroundings and are good at remembering images. They have a keen sense of direction and often enjoy maps. They take in information better through visual aids such as graphs, diagrams, pictures and colorful displays and usually enjoy visual arts such as drawing, painting and photography. They can visualize anything related to art, fashion, and decoration before creating it."],
    "Realistic": ["The Realistic personalities like to take a practical approach and, prefer to work with objects, Machines, tools, plants and animals. Their preference is for working with things and solving concrete problems."],
    "Investigative": ["The Investigative people like to observe, learn, investigate, evaluate or solve problems that require deep thinking and a scientific approach."],
    "Enterprising": ["Enterprising ones like to work by influencing persuading, leading or managing others for economic gain or achieving their goals. They enjoy leadership positions."],
    "Social": ["The Social people like to work with others to inform, enlighten, help, train or cure them and help them to live up to their potential. They are good at solving problems through discussion."],
    "Conventional": ["Conventional people like to work with data and have numerical or clerical ability which they use in establishing norms of work and ensuring adherence."],
    "Artistic": ["The Artistic ones are creative and innovative and like to work in unstructured situations where they can use their imagination and ideas."],
    "AC": ["ACs are slow, careful, methodical and meticulous and rarely make mistakes. They set their standards high. They are creative, unstructured, and innovative but also have a tendency to be very organized, conservative, and precise. This rare combination allows them to be highly creative but also allows them to keep track of small details. They love expressing their creativity and working in organized environments that are detail-oriented. This makes them incredibly valuable within organizations.", "ACs need order and structure, and they must know exactly where everything is in order to work most efficiently. At the same time, they are highly sensitive to their surroundings and have difficulty working in an area that is not aesthetically pleasant. When they take up a new project or topic, they can become bogged down in research or details.  As a result, they may find themselves in the middle of several unfinished projects, because they want whatever they do to be perfect. ", "By nature, they're somewhat critical of everyone. They really can't help this, because they're so acutely perceptive and like to speak their mind and they are upfront in their dealings. As a result, others see them as overbearing and abrasive."],
    "AE": ["AEs are charismatic and usually become the centre of attention amongst people. They tend to be innovative, expressive, and open-minded, while also tending to be energetic, persuasive, and adventurous. They seek independent and unstructured spaces where they can unleash their creativity. Aesthetics and environments that offer variety and change are much appreciated by them. ACs can be very assertive when it comes to expressing their points of view. They are also risk-takers and feel pure excitement when they develop new ideas.", "They can visualize a project that hasn't even been started they know exactly how things should be. They are impatient and don't have the time or patience for slow learners. They want people to play at their level or get out of the way. They are very resourceful and always find a way to get things done. AEs always love to perform, to make presentations, and to capture people's attention with their talent, wit, or their bold ideas. ", "An AE's dream job is to be somewhere in the limelight. Their goal is to get a captivated audience and sustain their interest. By nature they are go getters - If they cannot find a way to do something with the resources, people, and skills they have at hand, they will surely find another way. They are also tenacious and will not stop working until the job is done. However, they can be very self-critical, seeing errors that no one else notices. They work best alone-while directing others to do their parts, for their project. "],
    "AI": ["AIs are very expressive and intensely verbal. They have active imaginations and sometimes love exploring scientific curiosities. They love being creative with their ideas. They also have strong feelings, which they are very willing to express in detail, using an impressive vocabulary. However, they take time to think through things before they communicate them. While they are naturally strong at working through real world problems, their thinking can sometimes be overly idealistic and divorced from the intricacies and realities of human behavior. Nevertheless, they are extremely perceptive and can often sense the real meaning behind others' words and actions.", "AIs are typically well-rounded thinkers who are interested in a wide range of academic subjects. They often have a unique perspective and are brimming with ideas. They are also well suited for creative writing and research, as they can use language to explain, persuade, entertain, and enlighten and convey their ideas. Als need intellectual challenge and creative stimulation, or their minds tend to wander.", "They need complete autonomy in order to think and create. Once they take up an assignment, they can get so involved in it that they shut out the world until it's completed. They also have high standards and ideals and hold themselves and others to the same set of expectations. Because they are creative, intelligent, and idealistic, they can be very critical of the work of others. ", "Work that really fascinates them is that which involves exploring ideas, experimenting with them, and applying them to new situations.  Al's are intellectually curious and need to be challenged all the time. Highly technical work or anything that involves working directly with customers or work that is very repetitive are not something they enjoy much."],
    "AR": ["ARs are creative and visionary people who see things as they can be and not as they are. They have artistic vision combined with a hands-on ability to design and create things. They tend to be inquisitive, thoughtful, and analytical though their artistic side also makes them expressive, idealistic, and at times, impulsive. They have a great need for self-expression and love doing work that articulates their vision. They can work for hours without a break on their creative project. More importantly, they like being original with anything they take up. ", "AR's often feel best when they express themselves through tangible art, not words. They are accurate and practical, producing beautiful items without sacrificing functionality. They like to work with artistic mediums that play on their physicality. They are very sensitive as a person. Criticism, even when it's constructive, stings as far as they are concerned. On the other hand they are confident about their ability and their field of expression, and can be opinionated and critical about the work of others. ", "They may have a hard time collaborating in their work. This is because once they have a vision for how something should be, it's hard for them to see another person's viewpoint. Also, they tend to have a very introspective and intuitive way of thinking while they work, which can sometimes hurt them when they are working with people who tend to see practicality and functionality, over vision."],
    "AS": ["ASs combine a passion for the arts with a passion for people. They tend to be impulsive, creative, and sensitive with a propensity for cooperating with others and building meaningful relationships. They can be bohemian in their lifestyle but are good hearted, kind and caring and accepting of other people, cultures, and attitudes. They often have a preference for tasks that allow them to be inventive without a rigorous structure. They have a deep capacity for empathy with a strong need for self-expression and love creative activities such as drama, art, and music.", "They love to experiment with ideas and appreciate the beauty and depth of good art. They have a special blend of talents that allows them to create and perform. But because ASs are so social, they often prefer performance art such as music and theatre. In addition, their desire to connect with others makes them natural teachers. They love helping people grow.", "As an artistic nurturer, they are drawn to service but just any service will not do. To feel truly fulfilled they want to nurture people's artistic side.", "AS needs creative stimulation to be truly happy, and jobs that are too rigid, too repetitive, or too focused on money or competition are a bad fit. In addition, AS's are bound to suffer in jobs where they can't work with others in a constructive way. Remember, an AS is a nurturer, and that kind of personality trait doesn't lend itself well to working alone."],
    "CA": ["CAs are perfectionists who like everything in its place, organised, neat and clean. They are good at putting things together and like precision and structure in a plan with clear steps. They like activities that clearly spell out the tasks and require attention to detail and hate vague concepts. However, on some occasions, they like to find ways to express their creativity. Their artistic side loves the creative arts, following their instincts, and being original. This allows them to be highly creative and impulsive but also keep track of their work in full detail so that nothing falls through the cracks.", "CAs haCAs have a creative side that is at odds with their organized and systematic side. They have a difficult time making career and life choices as they are always trying to appeal to both sides of their personality. They crave independence, though at the same time, they like being given a set of tasks to complete. This diversity within their personalities can mean that they are very good at fitting within divergent roles that call for creativity and discipline. In general, they feel comfortable with rules. There are certain tried-and-true methods to use for preserving, organizing, and accessing the things that are important to the CAs, and they expect others to follow the same. ", "Sometimes working with others is a challenge for them as they do many things themselves in order to make sure they are done right. This may leave very little for others to do. It can be difficult for them to work with a team, as the errors made by others bother them. It may also be difficult for them to be an effective supervisor, as they tend to be critical of others' work and of their own as well."],
    "CE": ["CEs are goal-oriented, success-driven, agreeable, and ambitious. They are good at managing and operating businesses, leading projects both from top and bottom level, and selling to others by backing up their pitch with accurate data. CEs enjoy rules and regulations that keep things running smoothly. They are disciplined, focused, and industrious people, who demonstrate precision and correctness in their work. They are also responsible, trustworthy and reliable and because of their diligence and hard work, they never miss a deadline.", "CEs like working as part of a team or leading that team hence it can sometimes become difficult for them if they must work completely independently. In addition, they can sometimes struggle with work that is more abstract and without parameters. While they like initiating projects, they don’t see themselves as very creative and can struggle if they must come up with new, inventive ideas. They also enjoy structure, stability, and predictability in a job. They always take a conservative approach in anything they do. They believe, taking things slowly and carefully, and having all the facts in hand, helps avoid painful mistakes. ", "CEs tend to prefer working as part of a big organization and accomplishing things on a grander scale, than relatively small endeavors. "],
    "CI": ["CIs are methodical, persistent, and dependable while also tending to be analytical, scholarly, and avid readers. They are attentive, cautious and deliberate and know exactly what they're doing and why. They are also self-disciplined, meticulous and persistent enough to follow through, double check, and do things right. They typically enjoy taking time to think through abstract problems, perform thorough experiments, and conduct research. ", "They stand out from the crowd because of their ability to figure things out. They can build up a vast wealth of knowledge about a subject, then apply that knowledge to new situations to make an analysis or a prediction. They know how to take a project and break it down into manageable parts. Some CIs also have a gift for analysing numbers, charts, records, figures, contracts, reports, or maps. ", "CIs can be judgmental and view facts as being right or wrong. There isn't a lot of grey area. They however respect the rules and think everyone should follow them. There is no debate about rules in a CI mind. CIs like routines and doing things by the book. They hate working in loosely run situations where there is no structure. They need to work slowly and deliberately in order to be most effective; hence they do not appreciate interruptions, which break their intense concentration.", "CI's feel most at home when they're working on clearly defined projects, preferably ones that involve numbers, data sets, or codes such as computer programming languages; but pure number crunching is not what they are looking for. A CI likes a job which has elements of exploration and investigation, and preferably a good deal of authority along with an opportunity to understand their context."],
    "CR": ["CRs are steady and focused on their work, orderly, organized, obedient, and a follower of rules. They are structured, predictable and dependable and can do repetitive tasks without complaining. However, they feel better knowing where things are and what's expected of them. CRs also have a strong preference for physical and tactile activities. This makes them pragmatic with an appreciation for goal-oriented work where they can keep track of their progress. A CRs work tends to be very well-organized and consistent, and they don’t mind repetitive tasks when it is part of a big project. They get better with routine as they like to follow clearly defined rules and keep track of the details. However, they feel best when they produce tangible results that meet their high standards.", "CRs are very detail-oriented people with a knack for body-oriented tasks. They prefer structure and order over ambiguity. Projects with a clear beginning and end appeal to them, because they like it when things are well defined. They are very practical in their approach to the work they do and like a clear set of guidelines when setting out to do it. They feel their best when they produce tangible results that meet their high standards.", "CR's love getting things done, and they're always quick to develop a good system for doing it. That means working independently is appealing to them. It's not that they dislike people; it's just that it's usually simpler to do it alone rather than have to explain the system to others. They get irritated when others don't follow the rules."],
    "CS": ["CSs are steadfast, faithful and efficient. They take responsibility seriously and often carry through for others with adaptability and loyalty. They have an affinity for detail-oriented tasks that provide a lot of structure and dislike ambiguity. They are efficient systemisers and don't believe in wasting energy or time. They are also very loyal and dependable and often take the initiative to get things done.", "lso nurturing and caring. They enjoy being around other people where they can help or teach. They also enjoy building relationships with the customers. They gravitate toward tasks where they can be of value to someone in need. This makes them excellent team leaders where there is a set procedure or process to follow. Their main concern typically revolves around making others’ experiences more enjoyable, keeping accurate records, and being responsible for details hence they tend to dislike work where they have to study, research, or develop new ways of doing things. ", "They are comfortable with authority and like to receive appreciation from the people around them. They are very organized in their approach and this helps them in becoming very effective team leaders. Though they are friendly and outgoing, they also tend to be a no-nonsense personality.  They also set high standards for their work accomplishments and expect the same of others. They are also a stickler for workplace rules and protocols and ensure they are followed."],
    "EA": ["EAs are charismatic, ambitious, idealistic, visionaries who are dynamic, energetic, and full of ideas.  They are natural salesmen who always see the big picture, and can sell a project that hasn't even been started yet. They are very expressive and know how to win over a crowd. They can be charming and draw people in, especially when they need to get them on board.", "EAs enjoy creative activities like writing, art, and drama. They immerse themselves in their work and love to keep tabs on what others are doing. They can recognize a good thing when they see it. And when they get the chance, they'll throw themselves completely into a project, doing whatever they can to make sure it succeeds.", "They are dreamers who can visualize grand plans in exquisite detail. They like to use their intuition when developing new ideas and generally have a competitive nature. The are also multitalented, have boundless energy, and are able to juggle many projects simultaneously. They are willing to take risks and like environments where they are not tied down to any constraints. They tend to do well in roles where they can be managers for artistic work because they are able to make decisions that lead a group of people to carry out their vision.", "Because they enjoy directing the overall creative vision for a group of people, they can sometimes run into trouble when they have to work on projects individually that only contribute to a portion of the overall vision. While they know that every piece is crucial to the final execution of a project, they would rather oversee the production or work on something that’s more connected to the overall vision and artistic expression than the practical elements. They also tend to value variety over routine and can lose interest if circumstances stay the same for too long."],
    "EC": ["ECs are intense and competitive business minded workaholics. They are taskmasters who do whatever it takes to get things done. They will stop at nothing to get perfect final product and will not hesitate to take charge.  They tend to value success in business and gear much of their personal and professional decisions toward high accomplishments. They firmly believe that with the right system and structure, they can get the most out of people.", "Their drive is to create something that will bring wealth, recognition, power, change, or all of them. ECs want their efforts to result in something significant. They're not satisfied with the status quo, and they're always looking for ways to make things better. But while others come up with the grand plan, EC's are more likely to be the ones who put their mind to every detail of making the plan work. And it's something they do extremely well.", "They are self-confident, assertive, and ambitious while also tending to favour ideas that are calculated, measurable, and organized. They usually excel at initiating projects and organizing groups but these methodical people value concrete goals and clearly defined frameworks. Precision is important to them, lending them exceptional ability to assess technical data and statistics. ", "When there's work to be done, they are able to size up the players and the information quickly, and then tailor their approach to manage the situation, put everything together, and execute the plan. They don't shy away from complicated problems; rather, they dig in and figure out a way to make things work for everyone. The data is analysed, differences are addressed, tasks are delegated, deadlines are identified, negotiation occurs, and the project is completed."],
    "EI": ["EIs have the methodical nature and inquisitiveness of a researcher, combined with the risk-taking nature and opportunism of an entrepreneur. They are ambitious, confident and visionaries. Money, power, recognition, and winning are big motivators for them.  They can see the big picture and the final goal, and lead others to achieve it. They tend to be entrepreneurial, extroverted, and assertive while also being introspective, curious, and logical. These energetic and investigative people mix knowledge and persuasion to strong effect. They're in the game to win and want to take charge if they're going to win. ", "ility to take on the role of a leader but also like to be alone with their thoughts. They are intellectuals that like working with ideas, but their entrepreneurial nature allows them to persuade people to jump on board and work with them. They are self-assertive, confident, and enjoy discussing problems in depth to find practical solutions. They are logical, organised, agenda oriented careful planners. They understand that here are certain steps that need to be taken, in a certain order to get work done. They also know how to put all the pieces together make them work. ", "In their choice of career, they think big, want to rise to the top, be known as the best at what they do, and be respected and sought after. In the process, they may be slightly abrasive at times. In their mind, however, it’s often necessary to push people to get the right results. EIs also need independence in the work they do."],
    "ER": ["ERs are natural-born leaders and good at talking to people. They use this skill to persuade others and enjoy deal-making and directing. They are powerful negotiators and make great salespersons. Power and control thrill them and they can be forceful and blunt. They can be confident and courageous workaholics and take risks others won't. ", "They are naturally competitive people and love a challenge. ER's see the things they want and go after them until they've reached their goal. Giving up is not an option. They are active in their approach to solving problems, whether it’s physical or using their persuasive tendencies to gain greater status. They're persistent and confident enough to pursue their goal even when the odds are against them. They like giving directions and take pride in getting things done.  ", "Being action-oriented, they like to get a plan started and begin following it right away. They do not like work that involves long periods of intellectual effort, as kind of work is not tangible to them. They also tend to like working with their hands, solving real problems, and completing tasks that add value to the physical world. They are typically good at finding systematic and mechanical approaches to finishing tasks."],
    "ES": ["ESs are characterized by their keen ability to understand people and to read them quickly. They are energetic, community oriented, relationship centered and diplomatic. They are also resourceful and popular movers and shakers with excellent commination skills and talented soft sellers. They know how to keep people engaged and get their ideas across and are tactful enough to know when to hold their tongue. They are smooth operators who know how to sell ideas, concepts, and services without pushing them overtly.", "They thrive in environments where they are able to interact with others. They naturally persuade and support people in many situations. They are also energetic and confident. In addition, they are drawn to social causes. Their entrepreneurial spirit makes them masters of rounding people together for a good cause. With that spirit they enjoy launching projects and influencing other people, while the social side likes working with others, understanding how people are feeling, and generally concerning themselves with the welfare of others. ", "While ESs tend to be natural when it comes to working with other people or leading them, they can run into problems when they have to work on tasks independently alone, as they thrive and get inspired in the company of others. They usually prefer to avoid work that takes long periods of intellectual effort, which they see as lacking action. They may also avoid physical work which they do not feel is one of their strongest suits. ESs also get frustrated by jobs that give them plenty of chances to work with people but not the authority to make deals or the latitude to develop the relationships on their own terms. Es’s are exceedingly confident in their own abilities and generally chafe under tight rules and restrictions. They need to do things their way, because they know their way works."],
    "IA": ["IAs are highly observant and intuitive. They are also deep thinkers and very insightful. They are deeply intrigued by human behaviour and always want to know more about it. They are open-minded and love to be inspired and motivated by different ideas and theories. They also have the ability to express their ideas and concepts to others in an easy to understand and logical manner.", "IAs are introverted and avoid parties and social gatherings. They prefer intellectual pursuits and like to study, analyse, and come to conclusions about their experiences especially about people they have interacted with. They are fiercely independent and like to figure things out on their own and don't need others to agree. Given these traits, they can become very opinionated and often challenge other's views. They can be extremely critical and lack the patience needed to get others on the same page.", "IAs are driven by the desire to learn and investigate. What differentiates them however are their subject choices. They have intellectual curiosity but are more interested in the soft sciences than the hard ones - the sciences that explain how people act and why, such as sociology, psychology, and anthropology. They also enjoy subjects that involve investigation of ideas, of connections between things, and of history and culture.", "IAs are original thinkers and close observers. They know exactly what to do and not do when they set out to perform a task. However, IAs feel that physical work, repetitive work, or any work that doesn't stimulate the mind to think 'deep thoughts' or seek new understanding is tedious."],
    "IC": ["ICs are systematic thinkers who are concerned with what is, not with what might be. They are careful, methodical, deliberate, and accurate and, as a result, rarely wrong. They are also very cautious and observe, deliberate, decide, and plan before they act. They become deeply engaged with whatever they do to the extent of becoming obsessed and opinionated about it. They believe in doing things right and will work on something until it is absolutely perfect, correct, or ideal.", "ICs have excellent organizational skills and an exceptional ability to focus and 'zone in'. It is nearly impossible for them to work on more than one project at a time because they become consumed with whatever they're doing. They are bright and efficient, and a perfectionist, but they're also aware that nothing is ever perfect. ", "They are extremely moral and ethical, and tend to be a loud advocate of following proper procedures and rules. In their mind, these are not arbitrary but were created to ensure safety and accuracy. They can be very critical of co-workers when they don't live up to standards the ICs have set. They do not enjoy collaboration but are willing to work on a team when necessary. Even then they need their autonomy. "],
    "IE": ["IEs are result oriented and believe in getting the job done. They enjoy thinking and analysing as they want to know exactly how everything functions. They are very persuasive and have the uncanny ability to talk people into almost anything when they set their minds to it. Every move they make is calculated and they enjoy laying out the entire project on paper or on their computer. Most of all they are very entrepreneurial and competitive and play to win.", "IEs have the ability to size up abstract and complicated situations and solve problems in ways that others may not have thought of. They enjoy investigating, researching, and evaluating a situation, and then presenting a solution - often very persuasively. Their best fit would be a job that is highly specialized, requiring a significant amount of insight, education, and skill, because they like to be involved in projects that require a great deal of study and analysis.", "They love challenges and want to be the go-to person on all projects, as they want to be acknowledged as the leader. As a result, they take on (or promise) too much. Because they are careful researchers who possess a strong intellect, they genuinely feel that they can do the most successful job on almost any task, so it seems natural to take the lead. This sincere desire to reach the best possible outcome motivates them to take charge. But they are also motivated by recognition. They get a rush when others recognize their diligence and skill."],
    "IR": ["IRs are logical and analytical who rely on facts, not emotions to arrive at conclusions. They also like to break things down as it helps them figure out how to repeat successes and avoid failures. Their scientific orientation and a mathematical bent of mind make them intellectually curious, inquisitive and avid readers. The biggest thrill for IR comes from applying the knowledge they gain to test a hypothesis, find a new solution to a problem, or uncover new ways of understanding the world.", "However, they can be very critical and find faults with others. For IRs, work is an extension of the need to explore and find answers, and the work must be challenging. They are motivated by challenges, especially those requiring unconventional thinking. If someone insists something is impossible, they will find a way to make it possible and find a way to logically defend it. When something is too easy IRs have a hard time being attentive.", "IRs don’t need a lot of incentive to get their work done. The work in itself is the reward for them but they need autonomy to think things through. Once they're left alone, they'll get it done, even if they have to stay up all night. When it comes to getting things done, they don't have the patience to manage others. IRs want a career that in some way contributes to existing knowledge in their field of interest or at least applies that knowledge to solve problems. They are not interested in being managers."],
    "IS": ["ISs are naturally inquisitive people with a social side. They are observant, analytical, intellectual, methodical, and curious while also tending to be supportive, understanding, and patient. They spend a lot of time investigating ideas in their minds. They are able to grasp complicated concepts with relative ease and find meaning, information, connections, or patterns where most other people won’t be able to. They are also drawn to books, news stories, or documentaries that get their mind working. ", "ISs are introverted and set high standards for themselves and others. They feel best when they're in a small group of like-minded people. They don't like to rely on anyone besides themselves or a few trusted confidants and delegating work tasks is difficult for them. As a result, they take on more than they have time for. They recognise the importance of forming unbiased, fact-based conclusions before making any judgments and are objective in their approach to any situation.", "Though they can work very hard, they may feel bored by tasks that require a lot of repetition. Doing the same thing over and over, especially if it involves data or paperwork as opposed to people, just doesn't stimulate them. A job that involves frequent contact with others and makes a difference in their lives is a good match. For example, the healthcare industry is one field in which they find satisfaction, because so much of the work revolves around helping others, thinking objectively, and solving challenging problems. "],
    "RA": ["RAs are creative with an inclination for more hands-on, physical tasks. This unique combination means that they are very comfortable coming up with creative solutions to problems. They are inventive in the way that they solve practical problems and constantly use their imagination to create physical manifestations of their work. They have an exceptional ability to create work that is both practical and beautiful. They are good at sizing things up spatially and planning how to turn one thing into something else. Therefore, they often orient themselves towards technical arts and design. ", "They are perfectionists and won’t hesitate to find faults even with their own work. In their quest for perfection, they can spend hours performing repetitive tasks, and yet not ask anyone else for help. They also don't like being told what to do and don't like having to tell others, either. They enjoy getting things done alone. It is difficult for them to trust others to complete a project to their high standards. ", "RAs can be fairly opinionated and critical about the work of others, especially if they're collaborating with them or have to fix the mess created by them. Being introverted they prefer being alone-or with a few, very close friends or family members. To them, social climbing is a complete waste of time. They are very contented when they are able to build, assemble or craft something with their artistic flair."],
    "RC": ["RCs are logical, detail-oriented, organized and often have a strong preference for physical and tactile activities. They like to plan in detail and schedule even the minutest of activity. They work hard and are task oriented. They therefore will not accept any type of slack in getting something done. They are perseverant and will not give up easily till they have figured out a way to get the results needed. They can concentrate on their work for long periods of time without a break. They have a strong aptitude for mechanical thinking and can readily master jobs that require intense focus and precision. They are adept at figuring out how things are supposed to work and the ability to fix them, if they break down. ", "They feel best when they know exactly what they are doing and what is expected of them. Repetitive tasks don't bother them, and they have the focus and persistence to finish a task other would find tedious. However, they prefer their work, and their work hours, to be very structured. They like clear processes, patterns, routine, and schedules. At work and in their spare time, they'd prefer to focus on an area of specialization, and using their expertise gives them a great sense of accomplishment. ", "As a person they are somewhat reserved and introverted and don't like to be the centre of attention. Their discussions are usually short and to the point. In fact, they don't want any conversation while they're working, because they want to be able to focus. It makes them feel good when their expertise can help somebody out, but pleasing people is not their primary motivation. They are happiest when they meet their own high standards and accomplish the goals, they set for themselves. Usually their standards, are very high for others too, and they're liable to get agitated when they have to be around people who don't meet them. "],
    "RE": ["REs are logical, practical, and have a preference for managing or leading other people. They love to be in charge in any given situation and do what they say they'll do. With them things are black or white and there are hardly any grey areas. They tend to be opinionated and aggressive and when they want something done, they speak right up. They refuse to back down and get their way more often than not. They also tend to be autocratic but get things done. They have a clear agenda and are a great motivator of other people. However, they can be temperamental. ", "REs have an assertive, take-charge style, and are after results in everything they do. It comes naturally for them to enforce the rules and expect others to follow them. They get frustrated when others don't do their best or don't do a job right, because they know things don't get done if slackers are involved. Their assertiveness often lands them in the position of directing others, whether it's in a recognized way as a supervisor or a less formal way as an outspoken unofficial leader. ", "They are good at forming a quick opinion about how things should be done and are not shy about expressing it. They are action-oriented and don't see the point in discussing things when there's an obvious solution and work to be done. They are not social climbers but are interested in rising to the top. Partly this is because it will please their ego, and partly it's because they just think things will get done better if they are in charge. They want action, and not only do they want to be at the centre of it, but they also want to be directing it. For a RE to feel the fullest sense of purpose there must be a mission with a clear objective and a measurable outcome. They don't do particularly well with ambiguity and need to feel a sense of accomplishment when the results are achieved. They also need some degree of latitude to make their own decisions. "],
    "RI": ["RIs are systematic, conscientious and process-oriented in any task that they take up. They set high standards for the work they do and work that doesn’t meet their standards really gets them riled up. They like dealing with hard cold facts and believe in being rational and not emotional. They are introverted and are all at sea when they have to participate in social activities. They prefer to work alone and don’t have a lot of patience with people. At most, they will work with people who are prepared to put in the hard work and apply their knowledge. They also expect them to understand things the first time around. ", "RIs like working with scientific knowledge and its technical applications. They are technology-oriented and love new tools and gadgets. Working with their hands is important to them but doing just physical labour without any opportunity to explore and innovate is unacceptable to them. They also have a highly developed sense of logic, and a knack for solving problems. They take their work seriously and get excited by new and unusual tasks. Their primary urge is to innovate and find creative ways to make things work. They are project-oriented from beginning to end, and can’t relax until they have completed what they started. Once the project is finished. They feel stimulated by their work and proud of their ability to take theoretical knowledge and turn it into real things that people use. "],
    "RS": ["RSs are practical, self-reliant people that have a caring and nurturing nature. They are dependable, trustworthy and stand-by others whom they know. They like to forge relationships and keep in touch with everyone they have known. They love talking to others and take pride in being the link between people with differing interests and needs. In fact, they genuinely want to lend a helping hand and find a solution to problems others are facing.", "They are much less concerned with theories and big ideas than with what works. They value working with things they can see and touch and like physical challenges. They also love caring for animals. They are happiest in environments that are mechanical, industrial, or highly physical. But they also need more interaction with other people as part of their work. They feel pleased and empowered when people ask them to help them figure something out, and they're happy when a project is completed successfully and everyone is satisfied.", "They're pretty happy at work as long as they're helping others and able to have time for their family, friends, and community activities. They have a strong network of friends and schoolmates to draw on. They're the kind of person who always seems to have a friend of a friend who can help with almost any situation.", "An RS's would be terrified of working alone or working in a capacity that doesn't seem to have tangible results to help people, or both. RS also has a dominant realistic personality type, which means a job that doesn't have a hands-on component that involves producing or fixing something would also be a mismatch. "],
    "SA": ["SAs are positive thinkers who are kind, pleasant, and trusting. They thrive in social settings and routinely place others needs before their own while providing warmth and acceptance. Their empathetic and sensitive nature makes them natural people-persons. They are non-judgmental of others and can see many shades of grey and multiple sides of the same issue. They also don't hold grudges and give others the benefit of the doubt. They always strive to act in an honorable and respectful way no matter whom they are dealing with.", "They are fascinated with the designs of life and have a curiosity for interesting patterns or designs and a strong need for self-expression and socializing. Expression and originality are important to them. They are not methodical people and can often struggle with tasks that require precise approaches to determining solutions. They therefore often have a preference for tasks that allow them to be creative in an unstructured environment. ", "They are idealists, who believe in the potential of people, and want to do whatever they can to help them reach it. One of the ways they find fulfillment is by helping others find fulfillment using creativity and resourcefulness. However, their tendency to rely on their feelings to guide them through problems can sometimes come in the way of their ability to think through problems from a more objective point of view.  "],
    "SC": ["SCs have a natural ability to work with others, but also find time to work in solitude where they are able to organize their thoughts with detail-oriented tasks.  They are helpful, responsible, dependable, good communicators and team players. They are respectful of authority and like work to be performed in an orderly and organised manner. Their attention to detail and positive attitude enables them to perform consistently and maintain high quality of work. ", "SCs are hardworking people with a strong focus on people. They take pride in serving humanity and helping others. They tend to enjoy tasks that involve relationship building and social interaction. They gravitate toward tasks where they can be of value to someone in need. Their sensitivity and their inclination for persistence and efficiency make them ideal candidates where there is an opportunity to create enjoyable experiences for others.", "A job without a lot of structure is a bad fit for an SC and will probably result in anxiety. This is because they feel most comfortable when they are given specific procedures to follow. They do their best work when they have adequate time to understand the situation, chart the important details, and outline the steps they need to take. Nebulous goals and an unstructured workplace make SC's feel uneasy because they need to constantly know that things are working the way they're supposed to and they prefer to be told how things should be done rather than set their own parameters."],
    "SE": ["SEs are helpful, outgoing, and generous while also tending to be optimistic and persuasive. This combination makes them natural leaders whether they are working one on one or within groups. They put lots of energy into their personal and group relationships. They are averse to confrontation and sometimes ignore things that need to be addressed because they just don't want to deal with conflict.", "However, people love to tell SEs their problems, because SEs love to hear them and respond helpfully. If the problem really involves people, SEs want to make things better, and can be very creative in their efforts. Their disposition for teamwork and social interaction paired with their inclination to be enterprising means they work extremely well in situations that call for managing the safety and well-being of others.", "SEs also love being out in the world, talking with people, making friends and contacts, getting involved, and being part of exciting things that are happening. They like having a variety of experiences in their life, allowing them to learn a little bit about a lot of subjects. They don't really enjoy spending a lot of time in one place and would much rather be on the go, meeting, greeting, connecting, and facilitating. Because they are outgoing, conscientious, charming, and interested in others, people look to them for ideas, resources, and connections. ", "They work well with a group of people, which sometimes means they lack the drive to work where tasks are more independently oriented. They also don’t enjoy jobs where they have to get physical or build something with their hands. "], 
    "SI": ["SIs are people-persons who are both helpful and inquisitive. Their natural impulse is to be caring and empathetic. They are particularly rational and insightful and can see things many other people don't. However, they are tactful and get their point across without ruffling other people's feathers.", "An SI is largely an intellectual creature, one who devours books and magazines and isn't easily dissuaded by hard-to-solve problems. They are very systematic in their approach to solving problems. They make it a point to understand the designed processes, and tend to follow them step by step.  They also have a strong sense of professionalism and expect the same from others.", "SIs tend to be friendly, generous, and confident, preferring work where they have the opportunity to motivate others. They tend to enjoy work where they can enter into lively conversations, make plans for groups of people, and educate others. They are ideal for the role of initiating, organizing, and supervising activities which makes them natural leaders. ", "People place their trust in them, and they take that trust very seriously. People talk to them about their fears, worries, hopes, successes, and failures, and they pay close attention to those people listening patiently and asking questions. Helping people find better self-awareness, conquer the things that are difficult for them, improve their relationships, and generally live a more satisfying life is the ultimate payoff for SIs.", "Their natural tendency for educating and persuading can sometimes leave them demotivated when there are systematized activities such as data entry, making reports etc. to be done. They also tend to dislike work where they must physically exert themselves or perform athletically."],
    "SR": ["SRs are natural leaders for whom successfully guiding another toward an objective, or leading a team to get there as a group, is the ultimate accomplishment. SRs are the kind of person who believes anything worth doing is worth doing with all your heart. They are goal oriented and enjoy work that is physical and allows them to interact with others. They firmly believe in preparation and planning and in carrying out the plan. They are serious achievers and go-getters. They believe in hard work, for both themselves and those they work with. It's easy for them to be assertive when they believe others need to be told what to do to improve their lot.", "They love working together with a group and enjoy helping others develop their skills and relationships. They tend to be patient, understanding, and cooperative and often have a strong preference for physical and tactile activities. They usually prefer activities where there is not a lot of intellectual problem-solving, but rather, working on concrete problems that they can solve through action or a dialogue with others. They need to work within a structured framework to tackle the situation. They are organized and so when they take charge of a situation, they expect people to stick to the plan, or they will steer them back on track in a respectful, forceful manner. These traits make them the ultimate go-to people when it comes to fixing any problems both physical or interpersonal. "]

  }

  intelligence_description_bars(score1, score2 = 0) {
    let bar1 = document.getElementById('bar-1');
    bar1.setAttribute('style', `height: ${score1}%`);

    let bar2 = document.getElementById('bar-2');
    bar2.setAttribute('style', `height: ${score2}%`);
  }

  @Output() reduceSidebar = new EventEmitter();

  toggle_animate() {
    // this.isOpen = !this.isOpen;
    // this.isShown = !this.isShown;
    // this.showChatbot = !this.showChatbot;

    // console.log(`isOpen: ${this.isOpen} isShown: ${this.isShown} showChatbot: ${this.showChatbot}`);
    this.isExpanded = !this.isExpanded;
  }

  // debug method
  next_screen() {
    if (this.ExpandedScreenNum > 6) {
      this.finishAnimation();
    }
    this.ExpandedScreenNum += 1;
  }


  finishAnimation() {
    this.toggle_animate();
    this.ExpandedAnimationOver = true;

    this.reduceSidebar.emit();
  }


  intelligenceShow = 0;
  IntelligenceToShow($event) {
    this.intelligenceShow = $event;
  }

  // finishAnimation() {
  //   this.toggle_animate();
  //   this.animationOver = true;
  // }

  // next_screen() {
  //   if (this.isShownScreenNum > 6) {
  //     this.toggle_animate();
  //     this.animationOver = true;
  //   }
  //   this.isShownScreenNum += 1;
  // }

  // toggle_animate() {
  //   this.isOpen = !this.isOpen;
  //   this.isShown = !this.isShown;
  //   this.showChatbot = !this.showChatbot;

  //   console.log(`isOpen: ${this.isOpen} isShown: ${this.isShown} showChatbot: ${this.showChatbot}`);
  // }

}
