import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var Chart: any;

@Component({
  selector: 'app-intelligence-graph',
  templateUrl: './intelligence-graph.component.html',
  styleUrls: ['./intelligence-graph.component.scss']
})
export class IntelligenceGraphComponent implements OnInit {

  constructor(private http: HttpClient) { }

  // @Input() messages;
  @Input() intelligences;
  @Input() scores;
  @Input() which_test;
  // intelligences = [];
  // scores = [];

  ngOnInit(): void {
    this.gen_chart();
  }

  @Output() graphClicked = new EventEmitter<number>();
  graphClickEvent = (event, array) => {
    try {
      this.graphClicked.emit(array[0]._index);
    }
    catch (error) { }
  }

  colors_CIP = ['#002060', '#002060', '#ffc000', '#ffc000', '#ffc000', '#ffc000'];
  colors_MIP = [
    'rgba(0, 32, 96, 1)',
    'rgba(0, 32, 96, 1)',
    'rgba(0, 32, 96, 1)',
    'rgba(85, 142, 213, 1)',
    'rgba(85, 142, 213, 1)',
    'rgba(147, 205, 221, 1)',
    'rgba(147, 205, 221, 1)',
    'rgba(147, 205, 221, 1)',
  ];

  gen_chart() {
    var canvas: any = document.getElementById('intelligence-chart');
    var ctx = canvas.getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: this.intelligences,
        datasets: [{
          data: this.scores,
          backgroundColor: (this.which_test) ? this.colors_CIP: this.colors_MIP
          // [
          //   'rgba(0, 32, 96, 1)',
          //   'rgba(0, 32, 96, 1)',
          //   'rgba(0, 32, 96, 1)',
          //   'rgba(85, 142, 213, 1)',
          //   'rgba(85, 142, 213, 1)',
          //   'rgba(147, 205, 221, 1)',
          //   'rgba(147, 205, 221, 1)',
          //   'rgba(147, 205, 221, 1)',
          // ]
        }]
      },
      options: {
        onClick: this.graphClickEvent,
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: "#404040"
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: "#404040"
            },
            gridLines: {
              display: false
            }
          }]
        },
        plugins: {
          datalabels: {
            color: '#FFFFFF',
            anchor: 'end',
            align: 'start',
            formatter: function (value, context) {
              return value.toPrecision(4);
            }
          }
        },
      },
    });
  }

}
