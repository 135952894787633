<div class="container">
  <app-left-sidebar [@openClose]="isExpanded ? 'open':'close'" class="information" 
    [ExpandedAnimationOver]="ExpandedAnimationOver" [isExpanded]="isExpanded" [which_test]="which_test"
    [ExpandedScreenNum]="ExpandedScreenNum" [intelligences]="intelligences" [scores]="scores"
    (reduceSidebar)="finishAnimation()"></app-left-sidebar>
  <div class="chatbox" @showChatbot *ngIf="!isExpanded">
    <app-chatbot [messages]="messages" [sessionId]="sessionId" (which_test_emit)="check_test($event)" (messageEvent)="receiveMessage($event)"></app-chatbot>
  </div>
  <!-- <button (click)="checkdb()">dbtest</button> -->
</div>
<!-- <div class="test-buttons">
  TEST BUTTON: NOT FOR FINAL BUILD
  <button (click)="previous_screen()" style="position: inherit;">previous screen</button>
  <button (click)="toggle_animate()" style="position: inherit;">show</button>
  <button (click)="receiveMessage($event)" style="position: inherit;">natural</button>
  <button (click)="next_screen()" style="position: inherit;">next screen</button>
  <button (click)="show_messages()" style="position: inherit;">vars</button>
</div> -->