import { BrowserModule } from '@angular/platform-browser';
import { NgModule, EventEmitter } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { IntelligenceGraphComponent } from './intelligence-graph/intelligence-graph.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

import { FormsModule } from '@angular/forms';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';

//import { AngularFireModule } from "@angular/fire";

//import { app, auth, database, messaging, storage, firestore, functions, analytics, performance, remoteConfig } from 'firebase/app';


//import * as firebase from 'firebase/app';
/*  import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,
  StorageBucket
} from "@angular/fire/storage"; 
 */


/* const firebaseConfig = {
  apiKey: "AIzaSyD8jXzoheXKbW69jk0QqfTNHvDOgumI9gw",
  authDomain: "acz-bot-v2-pedvir-3e002.firebaseapp.com",
  databaseURL: "https://acz-bot-v2-pedvir-3e002.firebaseio.com",
  projectId: "acz-bot-v2-pedvir-3e002",
  storageBucket: "acz-bot-v2-pedvir-3e002.appspot.com",
  messagingSenderId: "953262279930",
  appId: "1:953262279930:web:3fd875d775eb17eea7cd43",
  measurementId: "G-T5R5XP7C2D"
}; */

@NgModule({
  declarations: [
    AppComponent,
    ChatbotComponent,
    IntelligenceGraphComponent,
    LeftSidebarComponent
	//AngularFireStorageModule
    //AngularFireModule.initializeApp(firebaseConfig, "cloud")
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule
  ],
  providers: [/* { provide: StorageBucket, useValue: "your" } */],
  bootstrap: [AppComponent]
})
export class AppModule { }
