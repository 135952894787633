import { Component, AfterViewChecked, ViewChild, ElementRef, HostBinding, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { trigger, transition, state, animate, style } from '@angular/animations';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { VoiceRecognitionService } from '../service/voice-recognition.service'

//const dialogflowURL = 'https://f2bd7ae1487e.ngrok.io/acz-bot-v2-pedvir-3e002/us-central1/dialogflowGateway';
const dialogflowURL = 'https://us-central1-guia-6054c.cloudfunctions.net/dialogflowGateway';

const dialogflowUploadURL = 'https://us-central1-acz-bot-v2-pedvir-3e002.cloudfunctions.net/dialogflowFileUpload';
//const dialogflowUploadURL = 'https://f2bd7ae1487e.ngrok.io/acz-bot-v2-pedvir-3e002/us-central1/dialogflowFileUpload';




@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  // animations: [
  // trigger('openClose', [
  //   state('open', style({
  //     backgroundColor: 'yellow',
  //     opacity: 1,
  //   })),
  //   state('close', style({
  //     backgroundColor: 'green',
  //     opacity: 1,
  //     width: '40%'
  //   })),
  //   transition('open <=> close', [
  //     animate('1s')
  //   ]),
  // transition('close => open', [
  //   animate('1s')
  // ]),
  // ])
  // ]
})
export class ChatbotComponent implements AfterViewChecked {
  // isOpen = true;

  title = 'Test';

  @Input() messages;
  
  

  private hasBaseDropZoneOver: boolean = false;
 
  
  // @Input() isShown;
  // @Input() isOpen;
  // @Input() showChatbot;

  // toggle() {
  //   this.isOpen = !this.isOpen;
  //   this.isShown = !this.isShown;
  //   this.showChatbot = !this.showChatbot;
  // }
  @Output() messageEvent = new EventEmitter<boolean>();
  message: boolean = true;
  // sendMessage() {
  //   this.messageEvent.emit(this.message)
  // }

  loading = false;
  startCue = false;
  // showYesNo = false;
  // hideButtons = false;

  which_test = -1; // 0 ~ MI | 1 ~ CI | -1 ~ not set
  @Output() which_test_emit = new EventEmitter();

  plane = faPaperPlane;

  container: HTMLElement;
  @ViewChild("ChatForm") form: ElementRef;

  // Randomly generated ID to maintain session with server
  @Input() sessionId: Number;
  private imageSrc: string = '';
  username:string = '';
  getusername:string = '';
  voiceStart = true;
  voiceEnd = false;
  isViewable = false;
  img_url ='assets/images/mic.gif';
  disabledInputText =false;
  constructor(private http: HttpClient,public service : VoiceRecognitionService) { this.service.init()}
  
  ngAfterViewChecked() {
    this.container = document.getElementById("EnableScroll");
    this.container.scrollTop = this.container.scrollHeight;
  }

  ngOnInit(): void {
	  localStorage.removeItem("username");
	  localStorage.removeItem("type");
	  this.isViewable = true;
	  this.service.value = '';
	 
    if (this.messages.length == 0) {
      this.handleUserMessage('Hi', 0);
	  
    }
  }
  returnZero() {
		return 0;
	}
 public toggle(): void { 
	  this.isViewable = !this.isViewable;
 
	  if(!this.isViewable){
		  this.service.start();
		  this.img_url ='assets/images/mic-animate.gif';
	  }else{
		  this.service.stop();
		  this.img_url ='assets/images/mic.gif';
	  }
 
 }

  clickMsg(str): void {
    var button: any = document.getElementsByClassName('yn-btn');
    this.handleUserMessage(str, 1);
    // var btn = <HTMLInputElement>button[button.length - 1];
    // btn.disabled = true;
    // if (button.length !== 1) {
    //   var btn1 = <HTMLInputElement>button[button.length - 2];
    //   btn1.disabled = true;
    // }
    for (let but of button) {
      but.disabled = true;
    }
  }

  checkMsg(className): void {
    var str = "";
    const checks: any = document.getElementsByClassName(className);
    for (let check of checks) {
      if (check.checked) {
        str += " " + check.value;
      }
    }
    this.handleUserMessage(str, 1);

    var ref: any;
    if (className == 'cb-cat') {
      ref = <HTMLInputElement>document.getElementById('submit-1');
    }
    if (className == 'cb-car') {
      ref = <HTMLInputElement>document.getElementById('submit-2');
    }
    if (className == 'cb-int') {
      ref = <HTMLInputElement>document.getElementById('submit-3');
    }
	if (className == 'cb-sc') {
      ref = <HTMLInputElement>document.getElementById('submit-4');
    }
	if (className == 'cb-Sports') {
      ref = <HTMLInputElement>document.getElementById('submit-5');
    }
	if (className == 'cb-hb') {
      ref = <HTMLInputElement>document.getElementById('submit-6');
    }
	if (className == 'cb-Sportsn') {
      ref = <HTMLInputElement>document.getElementById('submit-7');
    }
	if (className == 'cb-Family') {
      ref = <HTMLInputElement>document.getElementById('submit-8');
    }
    ref.disabled = true;

    const cbs: any = document.getElementsByClassName('cb');
    for (let cb of cbs) {
      if (!cb.checked) {
        cb.disabled = true;
      }
    }
  }
  uploadImage(e):void{
		// e.stopPropagation();
		 //e.preventDefault();
		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		var pattern = /image-*/;
		var reader = new FileReader();
		if (!file.type.match(pattern)) {
		alert('invalid format');
			return;
		}
			reader.onload = this._handleReaderLoaded.bind(this);
			reader.readAsDataURL(file);
  }
  
  _handleReaderLoaded(e) {
		let reader = e.target;
		let image = reader.result;
	    var matches = image.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
		
		
		
		this.imageSrc = reader.result;
	 	var alltext = {
          sessionId: this.sessionId,
		  type:2,
		  filetype:matches[1],
		  image:matches[2],
          queryInput: {
            text: {
              languageCode: 'en-US'
            }
          }
        };
		this.http.post<any>(
       dialogflowUploadURL,
        alltext
		
      ).subscribe(res => {
		  
		  this.handleUserMessage(res.filename, 2)
	  }); 
		
		//this.handleUserMessage(this.imageSrc, 2);
		
		
	}
  
  
  handleUserMessage(message, ifUser = 1) {
        console.log(this.messages);

    //console.log(message);
    const text = message;
	this.img_url ='assets/images/mic.gif';
	this.isViewable = true;
	this.service.value = '';
	this.service.text ='';
	
	this.service.stop();
    if (text.length == 0) {
      let lastMessage = '';

      if (this.messages.length != 0) {
        for (let i = this.messages.length - 1; i >= 0; --i) {
          if (this.messages[i].sender == 'Mathew') {
            lastMessage = this.messages[i].text[0];
            // console.log(this.messages[i]);
            break;
          } 
        }
      }

      this.addBotMessage(`Hey, that is an empty reply, lets start again. \n${lastMessage}`, {}, 'Front-End')
    }
    else {
      (ifUser) && (ifUser) !=2 ? this.addUserMessage(text) : null;
	
      if (ifUser == 1) {
        this.form.nativeElement.reset();
      }

      this.loading = true;

      // console.log(message);


      //HTTP Request to Gateway
	   
		var image = '';
		if(ifUser==2){
			image =  text;
		}
	  
	  var alltext = {
          sessionId: this.sessionId,
		  type:ifUser,
		  image:image,
          queryInput: {
            text: {
              text,
              languageCode: 'en-US'
            }
          }
        };
		
		 if(ifUser==2){
			alltext.queryInput.text.text ='Okay';
			this.addUserMessage(message,'img');
		} 
		
		//console.log(JSON.stringify(alltext));
		
		
		
		
		
		
      this.http.post<any>(
        dialogflowURL,
        alltext
		
      ).subscribe(res => {
        // adds every message in seperate block
        let text = [];
        let payload = {};
		
		this.messages.getusername={type:localStorage.getItem('type'),name:localStorage.getItem('username')};
		if(localStorage.getItem('type')=='user'){
			this.messages.getusername.name = this.messages.getusername.name.charAt(0).toUpperCase();
		  }
	    
		
		
		if(res.fulfillmentText=='What is your username?'){
			 
					if(!localStorage.getItem('username')){
						
						this.getusername = typeof res.outputContexts[0].parameters.fields.name.stringValue !='undefined'?res.outputContexts[0].parameters.fields.name.stringValue:'';
						localStorage.setItem('username', this.getusername);
						localStorage.setItem('type', 'user');
						this.messages.getusername = {type:localStorage.getItem('type'),name:localStorage.getItem('username').charAt(0).toUpperCase()};
				
						if(ifUser==2){
							localStorage.setItem('username', message);
							localStorage.setItem('type', 'img');
							this.messages.getusername = {type:localStorage.getItem('type'),name:localStorage.getItem('username')};
							this.addUserMessage(message,'img');
						}
					}
			
		}
		
		
		
		
		console.log(res);
		//console.log(dialogflowURL);
		
        for (var x of res.fulfillmentMessages) {
          // if('text' in x) {
          //   // console. (x.text.t ext);
          //   this.addBotMessage(x.text.text, res.outputContexts);
          // }
          // if('payload' in x) {
          //   this.messages.push({
          //     payload: x.payload
          //   });
          // }
		  
          if ('text' in x) {
            text.push(x.text.text);
          }
		  if ('image' in x) {
			  console.log(x.image.imageUri);
              text.push({imageUri:x.image.imageUri});
          }
        }
		
        if (res.webhookPayload) {
          payload = res.webhookPayload;
        }

        text.forEach((element) => {
          this.addBotMessage(element, res.intent.displayName, payload);
		 
		    if(res.fulfillmentText=='Thank you'){
			   this.disabledInputText=true;
		    } 
        });
        this.loading = false;
        //console.log(this.messages);

        if (this.messages[this.messages.length - 1].intent == 'GetDetails') {
          this.check_test();
        }

        if (this.messages[this.messages.length - 1].intent == "Give Result") {
          this.handleUserMessage('Okay', 0);
        }
      });
    }
  }

  check_test() {
    this.messages.forEach(element => {
      if (element.sender == 'You') {
        let upper = element.text.toUpperCase();
        if (upper.includes('ACZMI')) {
          this.which_test = 0;
        }
        else if (upper.includes('ACZCI')) {
          this.which_test = 1;
        }
      }
    });
    this.which_test_emit.emit(this.which_test);
  }

  // Helpers
  addUserMessage(text,type='user') {
    this.messages.push({
      text,
      sender: 'You',
      reply: true,
	  type: type,
      date: new Date()
    });
  }

  addBotMessage(text, intent, payload = {}, sender = 'Mathew') {
    if (intent == 'Give Result') {
      // if (text[0] == "In your case, your dominant intelligences are Musical-Rhythmic, Visual-Spatial and Intrapersonal. Okay?" ||
      //   text[0] == "In your case, your dominant intelligences are Realistic and Enterprising. Okay?") {//text == "Great! How was your experience with the Multiple Intelligence Test?" || 

      // text[0] == "In your case, your dominant intelligences are Musical-Rhythmic, Visual-Spatial and Intrapersonal Okay?") {
      // console.log(`isOpen: ${this.isOpen} isShown: ${this.isShown} showChatbot: ${this.showChatbot}`);
      // this.isOpen = !this.isOpen;
      // this.isShown = !this.isShown;
      // this.showChatbot = !this.showChatbot;
      this.messageEvent.emit(true);
      // console.log("executed animation");
    }
    this.messages.push({
      text,
      sender: sender,
      avatar: '/assets/bot.jpeg',
      date: new Date(),
      payload: payload,
      intent: intent
    });
  }
  // temp = {
  //   "payload": {
  //     "fields": {
  //       "Logical-Mathematical": {
  //         "listValue": {
  //           "values": [
  //             {
  //               "stringValue": "Professionals",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Economics and Finance",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Music Technology",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Mathematics",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Research",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Transportation",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Medicine",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Others",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Law",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Architects",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Paramedicine",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Education",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Information Systems",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Design",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Engineering",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Environmental Sciences",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Retail",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Governance",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Music Industry",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Humanitarian",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Business & Management",
  //               "kind": "stringValue"
  //             }
  //           ]
  //         },
  //         "kind": "listValue"
  //       },
  //       "Bodily-Kinesthetic": {
  //         "listValue": {
  //           "values": [
  //             {
  //               "stringValue": "Paramedicine",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Sports",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Others",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Engineering",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Fine & Plastic Arts",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Environmental Sciences",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Performing Arts",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Education",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Security and Defence",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Medicine",
  //               "kind": "stringValue"
  //             }
  //           ]
  //         },
  //         "kind": "listValue"
  //       },
  //       "Musical-Rhythmic": {
  //         "listValue": {
  //           "values": [
  //             {
  //               "stringValue": "Music Technology",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Paramedicine",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Business & Management",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Music Industry",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Education",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Journalism and Mass Communication",
  //               "kind": "stringValue"
  //             },
  //             {
  //               "stringValue": "Performing Arts",
  //               "kind": "stringValue"
  //             }
  //           ]
  //         },
  //         "kind": "listValue"
  //       }
  //     }
  //   }
  // }
}

