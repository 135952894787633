<div class="addback">
  <div class="wrapper">
  
  
    <div class="middle" name="EnableScroll" id="EnableScroll">
      <div *ngFor="let msg of messages">
        <div [ngSwitch]="msg.sender">
          <div *ngSwitchCase="'You'">
            <div class="outgoing">
			
			 <!-- <div [ngSwitch]="messages.getusername.type">
			 <div class="avataruser" *ngSwitchCase="'user'">{{messages.getusername.name?messages.getusername.name:''}}</div>
			  <div *ngSwitchCase="'img'"> <img class="avataruser" [src]="messages.getusername.name?messages.getusername.name:''" width="50" height="50"/></div>
			 </div>  -->
              <div class="bubble">
			  <img *ngIf="msg.type ==='img'; else noimage"  [src]="msg.text?msg.text:''" width="200" height="220"/>
				<ng-template #noimage>
                  {{msg.text}} 
                </ng-template>
			  </div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <div class="incoming">
              <div class="avatar">G</div>
              <div class="bubble">
			  
			  
			  
                <div *ngIf="msg.payload.fields; else elseBlock">

                  <div [ngSwitch]="msg.intent">
                    <div *ngSwitchCase="'GetDetails'">
                      {{msg.text}}
                    </div>

                    <div *ngSwitchCase="'CareerWhy - no'">
                      <div [ngSwitch]="msg.text[0]">
                        <div *ngSwitchCase="'Which of these categories interests you?'">
                          {{msg.text}}
                        </div>
                        <div
                          *ngSwitchCase="'We will now discuss your dominant intelligences and the potential career categories linked with them.'">
                          {{msg.text}}
                        </div>
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let intelligence of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              <div class="checkbox-title">
                               
								 With {{intelligence.key}} as your intelligence, these are the categories you can choose from
                              </div>
                              <br>
                              <div *ngFor="let i of intelligence.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-cat" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                              <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-1"
                                (click)="checkMsg('cb-cat')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
					
					<div *ngSwitchCase="'StudentClasss'">
                      <div [ngSwitch]="msg.text[0]">
                          {{msg.text}}
                        
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let StudentClasss of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              
                              <br>
                              <div *ngFor="let i of StudentClasss.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-sc" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                              <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-4"
                                (click)="checkMsg('cb-sc')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
					<div *ngSwitchCase="'Percentage'">
                      <div [ngSwitch]="msg.text[0]">
                          {{msg.text}}
                        
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let Family of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              
                              <br>
                              <div *ngFor="let i of Family.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-Family" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                              <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-8"
                                (click)="checkMsg('cb-Family')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
					<div *ngSwitchCase="'SportDeets'">
                      <div [ngSwitch]="msg.text[0]">
                          {{msg.text}}
                        
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let Hobby of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              
                              <br>
                              <div *ngFor="let i of Hobby.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-hb" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                              <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-6"
                                (click)="checkMsg('cb-hb')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
					<div *ngSwitchCase="'FamilyDetails - yes'">
                      <div [ngSwitch]="msg.text[0]">
                          {{msg.text}}
                        
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let Sports of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              
                              <br>
                              <div *ngFor="let i of Sports.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-Sports" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                              <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-5"
                                (click)="checkMsg('cb-Sports')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
					<div *ngSwitchCase="'FamilyDetails'">
                      <div [ngSwitch]="msg.text[0]">
                          {{msg.text}}
                        
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let Sports of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              
                              <br>
                              <div *ngFor="let i of Sports.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-Sportsn" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                              <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-7"
                                (click)="checkMsg('cb-Sportsn')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div *ngSwitchCase="'GiveCategoryInfo'">
                      <div [ngSwitch]="msg.text[0]">
                        <div *ngSwitchCase="'Which of them would you like to take up? Click on the checkboxes above to choose, and then click submit'">
                          {{msg.text}}
                        </div>
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let category of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              <div class="checkbox-title">
                                Under {{category.key}} category, you can pick the following career options:
                              </div>
                              <br>
                              <div *ngFor="let i of category.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-car" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                              <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-2"
                                (click)="checkMsg('cb-car')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div *ngSwitchCase="'Intelligences - yes'">
                      <div [ngSwitch]="msg.text[0]">
                        <div
                          *ngSwitchCase="'Your hobbies and subjects of study you like also support your intelligences. Isn’t that great!'">
                          {{msg.text}}
                        </div>
                        <div *ngSwitchCase="'Which of these categories interest you?'">
                          {{msg.text}}
						  
                        </div>
                        <div *ngSwitchDefault>
                          <form>
                            <div *ngFor="let interest of msg.payload.fields | keyvalue:returnZero">
                              <p>
                              <div class="checkbox-title">
                                In {{interest.key}} interest profile, you can pick the following career categories:
                              </div>
                              <br>
                              <div *ngFor="let i of interest.value['listValue']['values'] | keyvalue:returnZero">
                                <label>
                                  <input type="checkbox" name="check" class="cb cb-int" value={{i.value.stringValue}} />
                                  <span class="label-text">{{i.value.stringValue}}</span>
                                </label>
                              </div>
                              <p>
                                <br>
                            </div>
                            <div class="form-submit">
                              <input type="submit" value="Submit" class="cat-btn" id="submit-3"
                                (click)="checkMsg('cb-int')">
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
				<div *ngIf="msg.text.imageUri">
					<img src="{{msg.text.imageUri}}" style="max-width:300px;" />
				</div>
                <ng-template #elseBlock>
					<div *ngIf="!msg.text.imageUri">
						 {{msg.text}} 
					</div>
                 
                </ng-template>
              </div>

              <!--Non-Conflicting Intents-->
              <div [ngSwitch]="msg.intent">
                <!--Yes & No-->
                <div *ngSwitchCase="'TestExeperience'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <!-- <div *ngSwitchCase="'DislikedSubjects - Reason'">
                <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                <button class="yn-btn" (click)="clickMsg('No')">No</button>  
              </div>  -->
                <div *ngSwitchCase="'CareerWhy'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <!-- <div *ngSwitchCase="'Categories'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div> -->
                <div *ngSwitchCase="'ActivityPreReq'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
				
                <!--Okay-->
                <!-- <div *ngSwitchCase="'Give Result'">
                  <button class="yn-btn" (click)="clickMsg('Okay')">Okay</button>
                </div> -->
                <!--Class (9, 10, 11, 12, College)-->
                <div *ngSwitchCase="'TestExeperience - yes'">
                  <div *ngIf="which_test">
                    <!-- <button class="yn-btn" (click)="clickMsg('9th')">9th</button> -->
                    <button class="yn-btn" (click)="clickMsg('10th')">10th</button>
                    <button class="yn-btn" (click)="clickMsg('11th')">11th</button>
                    <button class="yn-btn" (click)="clickMsg('12th')">12th</button>
                    <!-- <button class="yn-btn" (click)="clickMsg('College')">College</button> -->
                  </div>
                  <div *ngIf="!which_test">
                    <button class="yn-btn" (click)="clickMsg('9th')">9th</button>
                    <button class="yn-btn" (click)="clickMsg('10th')">10th</button>
					<button class="yn-btn" (click)="clickMsg('11th')">11th</button>
                    <button class="yn-btn" (click)="clickMsg('12th')">12th</button>
                  </div>
                </div> 
              </div>

              <!--Conflicting Intents-->
              <div [ngSwitch]="msg.text[0]">
                <!--Yes & No-->
				
                <div *ngSwitchCase="'Okay, Are you also fond of sports?'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
				<div *ngSwitchCase="'Now, we will start our discussion on your career interests. Let’s now get to know you better!'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
				<div *ngSwitchCase="'Now, we will start our discussion on your multiple intelligence profile. Let’s now get to know you better!'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <div *ngSwitchCase="'Does that sound like you?'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <!-- <button class="yn-btn" (click)="clickMsg('No')">No</button> -->
                </div>
                <div *ngSwitchCase="'Fine, now to know a little more about you.'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <div
                  *ngSwitchCase="'Great, now let’s go ahead and take a look at the test scores in your report. But before that, let me tell you a little about the test itself.This test is based on the Theory of Multiple Intelligences and it profiles the eight different intelligences we possess.Would you like to know more about them?'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <div *ngSwitchCase="'Would you like to know more about  them?'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
				
                <div *ngSwitchCase="'Would you like to know more about them?'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <div *ngSwitchCase="'Are you sure you want to proceed with this category?'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <!--Okay-->
                <div
                  *ngSwitchCase="'The profiling indicates the dominant intelligences for each student, the ones that are supportive and ones that are dormant. Okay?'">
                  <button class="yn-btn" (click)="clickMsg('Okay')">Okay</button>
                </div>
				<div
                  *ngSwitchCase="'This brings us to the end of our counselling session. In case you want to interact with a counsellor you could book an appointment with our counsellor (extra fee applicable).'">
                  <button class="yn-btn" (click)="clickMsg('Yes')">Yes</button>
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
                <div *ngSwitchCase="'Is that okay?'">
                  <button class="yn-btn" (click)="clickMsg('Okay')">Okay</button>
				  <!-- <label for="yn-btn-file" class="yn-btn-file">
						Document Upload
				 </label>
				  <input type="file" id="yn-btn-file" style="display:none;" name="imageUrl"  (change)="uploadImage($event)" accept="image/*"/> -->
                </div>
                <div *ngSwitchCase="'Is  that okay?'">
                  <button class="yn-btn" (click)="clickMsg('Okay')">Okay</button>
                </div>
                <div *ngSwitchCase="'Okay?'">
                  <button class="yn-btn" (click)="clickMsg('Okay')">Okay</button>
				  
                </div>
				
				
                <div *ngSwitchCase="'The profiling indicates the dominant intelligences for each student, the ones that are supportive and ones that are dormant. You can learn more about these individual intelligences by clicking on the bar graph on the left panel after the next step. Okay?'">
                  <button class="yn-btn" (click)="clickMsg('Okay')">Okay</button>
                </div>
				
                <!--No-->
                <div *ngSwitchCase="'It seems that I have lost connection to the database. Enter 'No' to try to re-establish connection.'">
                  <button class="yn-btn" (click)="clickMsg('No')">No</button>
                </div>
				<div *ngSwitchCase="'How was your experience with the Multiple Intelligence Test?'">
								<button class="yn-btn" (click)="clickMsg('Engaging')">Engaging</button>
								<button class="yn-btn" (click)="clickMsg('Interesting')">Interesting</button>
								<button class="yn-btn" (click)="clickMsg('Intense')">Intense</button>
								<button class="yn-btn" (click)="clickMsg('Wonderful')">Wonderful</button>
								<button class="yn-btn" (click)="clickMsg('Fascinating')">Fascinating</button>
								<button class="yn-btn" (click)="clickMsg('Amazing')">Amazing</button>
                </div>
				<div *ngSwitchCase="'Please let us know how you found this interaction with Guia.'">
								<button class="yn-btn" (click)="clickMsg('Engaging')">Engaging</button>
								<button class="yn-btn" (click)="clickMsg('Interesting')">Interesting</button>
								<button class="yn-btn" (click)="clickMsg('Excellent')">Excellent</button>
								<button class="yn-btn" (click)="clickMsg('Wonderful')">Wonderful</button>
								<button class="yn-btn" (click)="clickMsg('Amazing')">Amazing</button>
								<button class="yn-btn" (click)="clickMsg('Other')">Other</button>
                </div>
				<div *ngSwitchCase="'How was your experience with the Career Interest Profilling Test?'">
								<button class="yn-btn" (click)="clickMsg('Engaging')">Engaging</button>
								<button class="yn-btn" (click)="clickMsg('Interesting')">Interesting</button>
								<button class="yn-btn" (click)="clickMsg('Intense')">Intense</button>
								<button class="yn-btn" (click)="clickMsg('Wonderful')">Wonderful</button>
								<button class="yn-btn" (click)="clickMsg('Fascinating')">Fascinating</button>
								<button class="yn-btn" (click)="clickMsg('Amazing')">Amazing</button>
                </div>
				<div *ngSwitchCase="'I didn\'t get that. How was your experience with the test?'">
								<button class="yn-btn" (click)="clickMsg('Engaging')">Engaging</button>
								<button class="yn-btn" (click)="clickMsg('Interesting')">Interesting</button>
								<button class="yn-btn" (click)="clickMsg('Intense')">Intense</button>
								<button class="yn-btn" (click)="clickMsg('Wonderful')">Wonderful</button>
								<button class="yn-btn" (click)="clickMsg('Fascinating')">Fascinating</button>
								<button class="yn-btn" (click)="clickMsg('Amazing')">Amazing</button>
                </div>
				<!-- <div *ngSwitchCase="'Now before we move ahead can you tell me who all are there in your family?'">
								<button class="yn-btn" (click)="clickMsg('Father')">Father</button>
								<button class="yn-btn" (click)="clickMsg('Mother')">Mother</button>
								<button class="yn-btn" (click)="clickMsg('Sister')">Sister</button>
								<button class="yn-btn" (click)="clickMsg('Brother')">Brother</button>
                </div> -->
				<div *ngSwitchCase="'Thank you. What is your father/ guardian’s profession?'">
								<button class="yn-btn" (click)="clickMsg('Government Service')">Govt. Service</button>
								<button class="yn-btn" (click)="clickMsg('Private Service')">Private Service</button>
								<button class="yn-btn" (click)="clickMsg('Defence Service')">Defence Service</button>
								<button class="yn-btn" (click)="clickMsg('Business')">Business</button>
								<button class="yn-btn" (click)="clickMsg('Farming')">Farming</button>
								<button class="yn-btn" (click)="clickMsg('Engineering')">Engineering</button>
								<button class="yn-btn" (click)="clickMsg('Medicine')">Medicine</button>
								<button class="yn-btn" (click)="clickMsg('Teaching')">Teaching</button>
								<button class="yn-btn" (click)="clickMsg('Law')">Law</button>
								<button class="yn-btn" (click)="clickMsg('Social work')">Social work</button>
					
								<button class="yn-btn" (click)="clickMsg('Journalism')">Journalism</button>
								<button class="yn-btn" (click)="clickMsg('Politics')">Politics</button>
								<button class="yn-btn" (click)="clickMsg('Homemaker')">Homemaker</button>
								<button class="yn-btn" (click)="clickMsg('Others')">Others</button>
								<button class="yn-btn" (click)="clickMsg('Not Applicable')">Not Applicable</button>
                </div>
				<div *ngSwitchCase="'What does your mother do?'">
								<button class="yn-btn" (click)="clickMsg('Government Service')">Govt. Service</button>
								<button class="yn-btn" (click)="clickMsg('Private Service')">Private Service</button>
								<button class="yn-btn" (click)="clickMsg('Defence Service')">Defence Service</button>
								<button class="yn-btn" (click)="clickMsg('Business')">Business</button>
								<button class="yn-btn" (click)="clickMsg('Farming')">Farming</button>
								<button class="yn-btn" (click)="clickMsg('Engineering')">Engineering</button>
								<button class="yn-btn" (click)="clickMsg('Medicine')">Medicine</button>
								<button class="yn-btn" (click)="clickMsg('Teaching')">Teaching</button>
								<button class="yn-btn" (click)="clickMsg('Law')">Law</button>
								<button class="yn-btn" (click)="clickMsg('Social work')">Social work</button>
								
								<button class="yn-btn" (click)="clickMsg('Journalism')">Journalism</button>
								<button class="yn-btn" (click)="clickMsg('Politics')">Politics</button>
								<button class="yn-btn" (click)="clickMsg('Homemaker')">Homemaker</button>
								<button class="yn-btn" (click)="clickMsg('Others')">Others</button>
								<button class="yn-btn" (click)="clickMsg('Not Applicable')">Not Applicable</button>
                </div>
              </div>
			  
			  
			  
			  
			  

            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loading">
        <div class="typing">
          <div class="bubble">
            <div class="ellipsis one"></div>
            <div class="ellipsis two"></div>
            <div class="ellipsis three"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div  id="js_scrollPrompt" class="scroll-prompt ng-star-inserted"><div class="scroll-prompt-shape"></div></div>
  <div class="bottom-bar">
    <div class="chat">
      <form (ngSubmit)="handleUserMessage(message.value)" id="ChatForm" name="ChatForm" #ChatForm>
		
        <input [disabled]="disabledInputText" type="text" placeholder="Type a message..." #message [(ngModel)]="service.value" name="message"   id="message"/>
        <span *ngIf="voiceStart" class="voice_start" (click)="toggle()" style="">
		<img alt="Start" style="width: 43px;margin: -12px 1px 0 -5px;" id="start_img" [src]="img_url"></span>
		
		<button [disabled]="disabledInputText" type="submit" class="send-btn">
          <fa-icon [icon]="plane"></fa-icon>
        </button>
      </form>
    </div>
  </div>

  <!-- <nav>
    <button (click)="toggle()">Toggle Open/Close</button>
  </nav>
<div [@openClose]="isOpen ? 'open' : 'close'" (@openClose.start)="onAnimationEvent($event)"
  (@openClose.done)="onAnimationEvent($event)" class="open-close-container">
  <p>The box is now {{ isOpen ? 'Open' : 'Closed' }}!</p>
</div> -->