<!-- <div class="background" *ngIf="animationOver">
    <div class="company-logo" @showLogo *ngIf="animationOver">
        <img src="../assets/images/Logo.png" />
    </div>
</div> -->
<!-- <button (click)="toggle_animate()" *ngIf="isShown">Back</button> -->
<div class="company-logo" @showLogo *ngIf="!isExpanded && !ExpandedAnimationOver">
    <img src="../assets/images/Logo.png" />
</div>
<div class="minimised-left" *ngIf="ExpandedAnimationOver">
    <div class="intelligence-show" @show>
        <div class="wrapper">
            <div class="middle">
                <div class="intelligence-data">
                    <div class="intelligence-text">
                        <div class="intelligence-head">
                            <h1>{{ intelligences[intelligenceShow] }}</h1>
                            <img class="intelligence-card-image"
                                src="../assets/images/intelligence_pics/{{intelligences[intelligenceShow]}}.png">
                        </div>
                        <!-- <div class="intelligence-image">
                        </div> -->
                        <div class="intelligence-body"
                            *ngFor="let text of intelligence_descriptions[intelligences[intelligenceShow]]">
                            <p>{{text}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-intelligence-graph [intelligences]="intelligences" [scores]="scores" [which_test]="which_test" class="graph"
        @show (graphClicked)="IntelligenceToShow($event)"></app-intelligence-graph>
    <div class="intelligence-bottom" @show>
        <div class="intelligence-hr"></div>
        <div class="intelligence-footnote">
            Click on any of the bars to get information about that Intelligence.
        </div>    
    </div>
</div>
<div class="expanded-left" *ngIf="isExpanded">
    <div class="screen-1" @show *ngIf="ExpandedScreenNum == 1">
        <div class="screen-1-text">Your {{which_test ? 'Career Interest Profiling':'Multiple Intelligence Profiling'}} Results</div>
        <app-intelligence-graph [intelligences]="intelligences" [scores]="scores" [which_test]="which_test" class="screen-1-graph">
        </app-intelligence-graph>
        <!-- <button class="screen-1-continue" (click)="next_screen()">Continue</button>  -->
        <div class="screen-3-actions">
            <span><button class="screen-3-continue" (click)="next_screen()">Continue</button></span>
        </div>

    </div>
    <div *ngIf="!which_test && ExpandedScreenNum > 1" class="test-container">
        <div class="screen-2" @show *ngIf="ExpandedScreenNum == 2">
            <div class="screen-2-text">Multiple Intelligence Profiling<br>Your Top 3 Intelligences</div>
            <div class="screen-2-cards">
                <div class="screen-2-card" *ngFor="let intelligence of intelligences.slice(0,3); index as i"
                    (click)="ExpandedScreenNum = i+3">
                    <div class="screen-2-card-text">{{intelligence}}</div>
                    <div class="screen-2-card-body">
                        <img class="screen-2-card-image"
                            src="../assets/images/intelligence_pics/{{intelligences[i]}}.png">
                        <div class="screen-2-card-score">{{ scores[i] }}%</div>
                    </div>
                </div>
            </div>
            <div class="screen-3-actions">
                <span><button class="screen-3-continue" (click)="finishAnimation()">Continue</button></span>
            </div>
            <div class="screen-2-footnote">
                You can click on any intelligence to see more about it.
            </div>
        </div>
        <div class="screen-3" @show *ngIf="ExpandedScreenNum == 3"
            (mousemove)="intelligence_description_bars(scores[0])">
            <div class="screen-3-data">
                <div class="wrapper">
                    <div class="middle">
                        <div class="screen-3-text">
                            <div class="screen-3-head">
                                <h1>{{ intelligences[0] }}</h1>
                            </div>
                            <div class="screen-3-body" *ngFor="let text of intelligence_descriptions[intelligences[0]]">
                                <p>{{text}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="screen-3-infographics">
                    <div class="screen-3-image">
                        <img class="screen-3-card-image"
                            src="../assets/images/intelligence_pics/{{intelligences[0]}}.png">
                    </div>
                    <div class="screen-bar">{{ scores[0].toFixed(0) }}%</div>
                </div>
            </div>
            <div class="screen-3-actions">
                <span><button class="screen-3-continue" (click)="ExpandedScreenNum = 2">Back</button></span>
            </div>
        </div>
        <div class="screen-3" @show *ngIf="ExpandedScreenNum == 4"
            (mousemove)="intelligence_description_bars(scores[1])">
            <div class="screen-3-data">
                <div class="wrapper">
                    <div class="middle">
                        <div class="screen-3-text">
                            <div class="screen-3-head">
                                <h1>{{ intelligences[1] }}</h1>
                            </div>
                            <div class="screen-3-body" *ngFor="let text of intelligence_descriptions[intelligences[1]]">
                                <p>{{text}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="screen-3-infographics">
                    <div class="screen-3-image">
                        <img class="screen-3-card-image"
                            src="../assets/images/intelligence_pics/{{intelligences[1]}}.png">
                    </div>
                    <div class="screen-bar">{{ scores[1].toFixed(0) }}%</div>
                </div>
            </div>
            <div class="screen-3-actions">
                <span><button class="screen-3-continue" (click)="ExpandedScreenNum = 2">Back</button></span>
            </div>
        </div>
        <div class="screen-3" @show *ngIf="ExpandedScreenNum == 5"
            (mousemove)="intelligence_description_bars(scores[2])">
            <div class="screen-3-data">
                <div class="wrapper">
                    <div class="middle">
                        <div class="screen-3-text">
                            <div class="screen-3-head">
                                <h1>{{ intelligences[2] }}</h1>
                            </div>
                            <div class="screen-3-body" *ngFor="let text of intelligence_descriptions[intelligences[2]]">
                                <p>{{text}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="screen-3-infographics">
                    <div class="screen-3-image">
                        <img class="screen-3-card-image"
                            src="../assets/images/intelligence_pics/{{intelligences[2]}}.png">
                    </div>
                    <div class="screen-bar">{{ scores[2].toFixed(0) }}%</div>
                </div>
            </div>
            <div class="screen-3-actions">
                <span><button class="screen-3-continue" (click)="ExpandedScreenNum = 2">Back</button></span>
            </div>
        </div>
    </div>
    <div *ngIf="which_test && ExpandedScreenNum > 1" class="test-container">
        <div class="screen-2" @show *ngIf="ExpandedScreenNum == 2">
            <div class="screen-2-text">Career Interest Profiling<br>Your Top 3 Interest Profiles</div>
            <div class="screen-2-cards">
                <div class="flip-card" *ngFor="let intelligence of intelligences.slice(0,3); index as i">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="screen-2-card-text">{{intelligence}}</div>
                            <div class="screen-2-card-body">
                                <img class="screen-2-card-image"
                                    src="../assets/images/intelligence_pics/{{intelligences[i]}}.png">
                                <div class="screen-2-card-score">{{ scores[i] }}%</div>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            {{intelligence_descriptions[intelligence]}}
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div class="screen-3-actions">
                <span><button class="screen-3-continue" (click)="next_screen()">Continue</button></span>
            </div>
            <div class="screen-2-footnote">
                You can hover over any of the cards to see more about the intelligence.
            </div>
        </div>
        <div class="screen-3" @show *ngIf="ExpandedScreenNum == 3"
            (mousemove)="intelligence_description_bars(scores[0], scores[1])">
            <div class="screen-3-data">
                <div class="wrapper">
                    <div class="middle">
                        <div class="screen-3-text">
                            <div class="screen-3-head">
                                <h1>Your Career type is {{ intelligences[0] }} - {{ intelligences[1] }}
                                </h1>
                            </div>
                            <div class="screen-3-body"
                                *ngFor="let text of intelligence_descriptions[intelligences[0][0] + intelligences[1][0]]">
                                <p>{{text}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="screen-3-infographics">
                    <div class="screen-3-image">
                        <img class="screen-3-card-image"
                            src="../assets/images/intelligence_pics/{{intelligences[0]}}.png">
                    </div>
                    <div class="screen-3-image">
                        <img class="screen-3-card-image"
                            src="../assets/images/intelligence_pics/{{intelligences[1]}}.png">
                    </div>
                    <div class="screen-bars">
                        <div class="screen-bar" id="bar-1">
                            <div class="screen-bar-score">{{ scores[0] }}%</div>
                            <div class="screen-bar-intelligence">{{ intelligences[0] }}</div>
                        </div>
                        <div class="screen-bar" id="bar-2">
                            <div class="screen-bar-score">{{ scores[1] }}%</div>
                            <div class="screen-bar-intelligence">{{ intelligences[1] }}</div>
                        </div>    
                    </div>
                </div>
            </div>
            <div class="screen-3-actions">
                <span><button class="screen-3-continue" (click)="finishAnimation()">Continue</button></span>
            </div>
        </div>
    </div>
</div>